var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProductSettingsList',{attrs:{"mode":"channel","channel":_vm.channel,"title":"Product List - Channel"},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('CDataTable',{attrs:{"items":_vm.products,"items-per-page":10,"pagination":"","loading":_vm.isLoading,"items-per-page-select":"","cleaner":"","sorter":"","hover":"","table-filter":"","fields":[
             { key:'imageUrl', _style: 'width: 60px', label: ''},
             { key:'sku', _style: 'width: 160px' }, 
             { key:'identifier', _style: 'width: auto', label: _vm.productIdentifier },
             { key:'reviewUrl', },
             { key:'prio' },
             { key:'isActive', label:'Active', _style: 'width: 80px;'}
             ]},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"imageUrl",fn:function(ref){
             var item = ref.item;
return [_c('ProductsListImageColumn',{attrs:{"rowData":item,"disabled":!item.isActive}})]}},{key:"identifier",fn:function(ref){
             var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.channelProductId)+" ")])]}},{key:"sku",fn:function(ref){
             var item = ref.item;
return [_c('td',[_c('div',{staticClass:"mt-0 mb-0"},[_c('span',{staticClass:"strong text-nowrap"},[_vm._v(_vm._s(item.sku))])]),(!item.allBonusesAvailable)?_c('CLink',{class:{'text-danger':true, 'opacity-1': !item.isActive},attrs:{"to":("/products/view/" + (item.sku) + "?rewards=true")}},[_c('CIcon',{staticStyle:{"transform":"translateY(-2px)"},attrs:{"name":"cil-gift"}}),_vm._v(" "),_c('strong',[_vm._v("!")])],1):_vm._e()],1)]}},{key:"reviewUrl",fn:function(ref){
             var item = ref.item;
return [_c('td',[((item.configured === false || !item.isActiveInChannel || !item.isActive) && !item.reviewUrl && !(_vm.channelData && _vm.channelData.reviewUrl))?_c('span',{staticClass:"text-secondary"},[_vm._v("Not configured")]):_vm._e(),(item.reviewUrl)?_c('span',{class:(item.isReviewActive && item.isActiveInChannel && item.isActive) ? 'text-primary' : 'text-secondary'},[_vm._v(_vm._s(item.reviewUrl))]):_vm._e(),(!item.reviewUrl && _vm.channelData && _vm.channelData.reviewUrl)?_c('span',{class:(item.isReviewActive && item.isActiveInChannel && item.isActive) ? 'text-info' : 'text-secondary'},[_vm._v(_vm._s(_vm.channelData.reviewUrl))]):_vm._e(),(!item.reviewUrl && !_vm.channelData.reviewUrl && item.isReviewActive && item.isActiveInChannel && item.isActive)?_c('span',{staticClass:"text-secondary"},[_c('span',{staticStyle:{"transform":"translateY(-1px)","display":"inline-block"}},[_c('CIcon',{staticClass:"text-danger mr-1",attrs:{"name":"cil-warning"}})],1),_vm._v("Missing review url")]):_vm._e(),(!item.isReviewActive && (item.configured !== false))?[_c('br'),_c('CBadge',{attrs:{"color":"secondary"}},[_vm._v("Skip Review")])]:_vm._e(),(item.configured !== false)?[_c('br'),_vm._v("Min. rating: "),_c('Rating',{attrs:{"rating":item.minRating}}),_c('span',{staticClass:"ml-2 font-weight-500"},[_vm._v("Cap:")]),_c('span',{staticClass:"ml-1"},[_vm._v("/day: ")]),(item.maxDaily)?_c('strong',{staticClass:"text-primary"},[_vm._v(_vm._s(item.maxDaily))]):_c('strong',{staticClass:"text-success"},[_vm._v("∞")]),_c('span',{staticClass:"ml-1"},[_vm._v("/week: ")]),(item.maxWeekly)?_c('strong',{staticClass:"text-primary"},[_vm._v(_vm._s(item.maxWeekly))]):_c('strong',{staticClass:"text-success"},[_vm._v("∞")])]:_vm._e()],2)]}},{key:"isActive",fn:function(ref){
             var item = ref.item;
return [_c('td',[((item.configured !== false))?_c('CBadge',{attrs:{"color":_vm.getBadge(item.isActive && item.isActiveInChannel)}},[_vm._v(" "+_vm._s(item.isActive && item.isActiveInChannel ? "Active" : "Disabled")+" ")]):_c('CBadge',{attrs:{"color":"warning"}},[_vm._v("Not configured")])],1)]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }