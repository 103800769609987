<template>
    <ProductSettingsList mode='channel' :channel='channel' title='Product List - Channel'>
        <template v-slot:table>            
            <CDataTable
                :items="products"
                :items-per-page="10"
                pagination
                :loading="isLoading"      
                items-per-page-select       
                cleaner
                sorter
                hover
                table-filter
                 :fields="[
                 { key:'imageUrl', _style: 'width: 60px', label: ''},
                 { key:'sku', _style: 'width: 160px' }, 
                 { key:'identifier', _style: 'width: auto', label: productIdentifier },
                 { key:'reviewUrl', },
                 { key:'prio' },
                 { key:'isActive', label:'Active', _style: 'width: 80px;'}
                 ]"
                 @row-clicked='rowClicked'
                >
                <template #imageUrl="{item}">
                   <ProductsListImageColumn :rowData='item' :disabled="!item.isActive" />
                </template>
                <template #identifier="{item}">
                    <td>
                        {{ item.channelProductId }}
                    </td>
                </template>
                <template #sku="{item}">
                    <td>
                        <div class="mt-0 mb-0"><span class="strong text-nowrap">{{item.sku}}</span></div>
                        <CLink v-if="!item.allBonusesAvailable" :to="`/products/view/${item.sku}?rewards=true`" 
                             :class="{'text-danger':true, 'opacity-1': !item.isActive}" 
                        ><CIcon name="cil-gift" style="transform: translateY(-2px)" /> <strong>!</strong></CLink>
                    </td>
                </template>
                <template #reviewUrl="{item}">
                    <td>
                        <span v-if="(item.configured === false || !item.isActiveInChannel || !item.isActive) && !item.reviewUrl && !(channelData && channelData.reviewUrl)" class="text-secondary">Not configured</span>
                        <span :class="(item.isReviewActive && item.isActiveInChannel && item.isActive) ? 'text-primary' : 'text-secondary'" v-if="item.reviewUrl">{{ item.reviewUrl }}</span>
                        <span :class="(item.isReviewActive && item.isActiveInChannel && item.isActive) ? 'text-info' : 'text-secondary'" v-if="!item.reviewUrl && channelData && channelData.reviewUrl">{{ channelData.reviewUrl }}</span>
                        <span v-if="!item.reviewUrl && !channelData.reviewUrl && item.isReviewActive && item.isActiveInChannel && item.isActive" class="text-secondary"><span style="transform:translateY(-1px); display:inline-block"><CIcon name="cil-warning" class="text-danger mr-1" /></span>Missing review url</span>
                        <template v-if="!item.isReviewActive && (item.configured !== false)">
                            <br /><CBadge color="secondary">Skip Review</CBadge>
                        </template>
                        <template v-if="item.configured !== false">
                            <br />Min. rating: <Rating :rating="item.minRating" />  
                            <span class="ml-2 font-weight-500">Cap:</span>  
                            <span class="ml-1">/day: </span>
                            <strong v-if="item.maxDaily" class="text-primary">{{ item.maxDaily }}</strong>
                            <strong v-else class="text-success">∞</strong>
                            <span class="ml-1">/week: </span>
                            <strong v-if="item.maxWeekly" class="text-primary">{{ item.maxWeekly }}</strong>
                            <strong v-else class="text-success">∞</strong>
                        </template>
                    </td>
                </template>
                <template #isActive="{item}">
                    <td>
                    <CBadge v-if="(item.configured !== false)" :color="getBadge(item.isActive && item.isActiveInChannel)">
                        {{ item.isActive && item.isActiveInChannel ? "Active" : "Disabled" }}
                    </CBadge>
                    <CBadge color="warning" v-else>Not configured</CBadge>
                    </td>
                </template>                
            </CDataTable>
        </template>
    </ProductSettingsList>
</template>
<script>
import ProductSettingsList from '@/components/ProductSettingsList';
import ProductsListImageColumn from '@/components/ProductsListImageColumn';
import Rating from '@/components/Rating'
import { mapGetters, mapActions } from 'vuex';
import { actionNames } from '@/store/modules/products/actions';

export default {
    props: {
        channel: String
    },
    components: {
        ProductSettingsList,
        ProductsListImageColumn,
        Rating
    },
    computed: {
        ...mapGetters({
            products: 'products/products',
            channelData: 'products/channel', 
            isLoading: 'products/loading',
            merchantId: 'merchantId' }
        ),
        productIdentifier() {
            return this.channelData && this.channelData.productIdentifier ? this.channelData.productIdentifier : "ID";
        },
    },
    methods: {
        ...mapActions({loadProducts: actionNames.LOAD_PRODUCTS_CHANNEL}),
        rowClicked(item) {
            this.$router.push(`/products/view/${item.sku}?channel=${this.channel}`);
        },
        getBadge (status) {
            switch (status) {
                case true: return 'success';
                default: return 'secondary';
            }
        }
    },
    watch: {
        channel() {
            this.loadProducts({channel: this.channel});
        }
    },
    created() {
        //this.showError = true;
        if (this.merchantId != null) {
            this.loadProducts({channel: this.channel});
        }        
    }
}
</script>
